
.shortcut-modal {
  margin: 0 auto;
  max-width: 420px;

  &_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}
