
[data-snackbar-notification] {
  .snackbar-notification {
    inset: 0;
    height: initial;

    // выше BaseDialog и SideMenu
    z-index: 10003;

    .v-snack__wrapper {
      min-width: 304px;
      width: auto;
    }

    &.snackbar-notification_dialog .v-snack__wrapper {
      bottom: 56px;
    }
  }
}
