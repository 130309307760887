
@import 'frontend/styles/variables';

.b-footer {
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  padding: 40px 0 64px;
  color: $ui-kit-text;
  background-color: $ui-kit-bg-secondary;
  clear: both;

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px;
  }

  &__column {
    padding: 0 12px;
  }

  &__info-registration {
    flex-basis: 476px;
  }

  &__info-address {
    flex-basis: 370px;
  }

  &__info-additional-item {
    position: relative;
    display: flex;

    &:not(:first-child) {
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: 13px;
        width: 2px;
        height: 2px;
        border-radius: 100%;
        background-color: currentColor;
      }
    }
  }

  &__warning {
    max-width: 714px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
