
@import 'frontend/styles/variables';

#app .base-toolbar {
  border-bottom: 1px solid $ui-kit-bg-gray-60;

  &_borderless {
    border-bottom: 0;
  }
}
