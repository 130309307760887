
@import 'frontend/styles/variables';

.the-header-line {
  &_sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    transform: translateY(-105%);
    transition: transform 0.3s ease;

    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, 0.2),
      0 1px 1px rgba(0, 0, 0, 0.14),
      0 1px 3px rgba(0, 0, 0, 0.12);

    &-active {
      transform: translateY(0);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    background-color: $ui-kit-bg-gray-0;
  }

  &__hamburger-btn {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent;
  }

  &__logo {
    object-fit: contain;
    width: 184px;
    height: 28px;
  }

  &__search-btn {
    cursor: pointer;
    position: relative;
    font-size: 24px;
    margin: 0;
  }

  &__search-btn-empty {
    position: relative;
    width: 48px;
  }

  &__keyboard-input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    border-width: 0;
    padding: 0;
  }
}
