
@import 'frontend/styles/variables';

.b-intro {
  line-height: initial;
  background: $ui-kit-bg-gray-0;
  padding: 40px 0;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);

  &__header {
    text-align: center;
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
  }

  &__search-container {
    margin-top: 24px;
  }

  &__search-wrapper {
    transform: translateY(50%);
    margin-bottom: 24px;
    position: relative;
  }

  &_padding_compact {
    padding: 20px 0 0;
  }

  &__stat {
    margin-top: 16px;
  }

  &__stat-reviews-item {
    background: $ui-kit-bg-gray-40;
    text-align: center;
    padding: 15px 8px;
    border-radius: 5px;
  }

  &__stat-reviews-item-num {
    font-size: 30px;
    font-weight: 500;
  }

  &__stats-container {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__stat-item {
    flex: 0 0 50%;
    margin: 12px 0;
  }

  &__stat-item-img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27px auto;

    &_type {
      &_reviews {
        background-image: url('/static/_v1/pd/icons/advantages/review.svg');
      }

      &_appointment {
        background-image: url('/static/_v1/pd/icons/advantages/appointment.svg');
      }

      &_doctor {
        background-image: url('/static/_v1/pd/icons/advantages/doctor.svg');
      }

      &_lpu {
        background-image: url('/static/_v1/pd/icons/advantages/lpu.svg');
      }

      &_users {
        background-image: url('/static/_v1/pd/icons/advantages/group-users.svg');
      }
    }
  }

  &__stat-item-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 11px;
    padding-right: 24px;
    box-sizing: border-box;
    width: calc(100% - 40px);
  }

  &__stat-item-num {
    font-size: 18px;
    font-weight: 500;
  }
}

.b-pd-nav {
  line-height: initial;
  padding-bottom: 40px;

  &__header {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    display: inline-flex;
    align-items: center;
    font-size: 25px;
    font-weight: 400;

    &::before {
      content: '';
      flex: 0 0 auto;
      display: inline-flex;
      width: 43px;
      height: 49px;
      background: url('/static/_v1/pd/icons/fire-red-with-shadow.svg') left center / contain no-repeat;
    }
  }

  &__title-text {
    color: $ui-kit-text-secondary;

    &::before {
      content: '';
    }
  }

  &__title-town-link {
    border-bottom: 2px dashed $ui-kit-text;
    font-weight: 500;
    color: $ui-kit-text;
    text-decoration: none;

    &:hover {
      color: $ui-kit-text;
    }
  }

  &__heading {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    min-height: 36px;
  }

  &__heading-text {
    background-image: url('/static/_v1/pd/icons/fire-red.svg');
    background-position-x: 3px;
    background-repeat: no-repeat;
    color: $ui-kit-text-secondary;
    display: block;
    margin-bottom: 6px;
    padding: 1px 0 0 22px;
    text-transform: uppercase;
    transform: translateX(-6px);
  }

  &__heading-town-link {
    border-bottom: 2px dashed $ui-kit-text;
    color: $ui-kit-text;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: $ui-kit-text;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -32px;
  }

  &__cards-item {
    padding: 0 16px;
    margin-bottom: 32px;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
  }
}

.b-card-nav {
  line-height: initial;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background-color: $ui-kit-bg-gray-0;
  box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
  transition: 0.2s ease-in-out;
  will-change: transform;

  &:hover {
    box-shadow: 0 15px 20px rgba(151, 158, 167, 0.2);
    transform: translateY(-10px);
  }

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    color: $ui-kit-text;
    text-decoration: none;
  }

  &__header:hover &__title {
    text-decoration: underline;
  }

  &__main {
    flex: 1 1 auto;
    padding: 0 24px;
  }

  &__footer {
    padding: 0 24px 24px;
  }

  &__all-count {
    font-size: 18px;
    font-weight: 59;
    line-height: 130%;
    color: $ui-kit-text-secondary;
    text-decoration: none;
  }

  &__list {
    margin: 0;
    padding: 16px 0;
    border-top: 1px solid $ui-kit-bg-gray-60;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
  }

  &__item-link {
    flex: 1;
    display: block;
    padding: 8px 16px 8px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    color: #5a5c61;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: $ui-kit-primary;
      text-decoration: underline;
    }
  }

  &__item-count {
    padding: 8px 0 8px 16px;
    font-size: 16px;
    line-height: 130%;
    color: $ui-kit-text-secondary;
  }
}

@mixin background-transition-timing {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin reactions($hoverBack, $pressedBack, $fontColor) {
  @include background-transition-timing;

  &:hover {
    background-color: $hoverBack;
    color: $fontColor;
  }

  &:active {
    background-color: $pressedBack;
    color: $fontColor;
  }
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: $ui-kit-bg-gray-60;
  color: $ui-kit-text;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  appearance: none;

  &_light {
    background-color: $ui-kit-bg-primary;
    color: $ui-kit-primary;

    @include reactions(#D1E8F4, #C1E0F0, $ui-kit-primary);
  }
}
