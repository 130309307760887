
.b-the-header {
  position: relative;

  &__change-flavour {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    text-align: center;
  }
}

.b-change-flavour {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  border-bottom: 1px solid #444444 !important;
  cursor: pointer;
  border-radius: 0 !important;
  margin: 0 auto;
  display: block;
  white-space: nowrap;
  box-shadow: none !important;
  width: 130px !important;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  text-align: center;
}

.b-change-flavour:hover {
  color: red !important;
  border-bottom-color: red !important;
}
