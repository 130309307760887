
@import 'frontend/styles/variables';

.dropdown-item {
  display: flex;
  align-items: center;
  min-height: 44px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover,
  &_selected {
    background-color: $ui-kit-bg-gray-40;
    transition: background-color 0.15s ease-in-out;
  }

  &__img {
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    object-fit: contain;
    margin-right: 24px;
  }
}
