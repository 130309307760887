.b-expansion-panels {
  &__head {
    display: flex;
    align-items: center;
    font-weight: inherit;
    cursor: pointer;
  }

  &__body {
    height: 0;
    display: none;
    overflow: hidden;
  }

  &__button {
    all: inherit;
    width: 100%;
    padding: 0;

    &:hover {
      background-color: inherit;
    }

    &:focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }

    &_has-arrow {
      position: relative;
      min-height: 24px;
      padding-right: 44px;

      &::after {
          /* use !important to prevent issues with browser extensions that change fonts */
          /* stylelint-disable-next-line */
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          font-size: 24px;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

        content: '\E92C'; // ui-icon-arrow-down
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      }

      &[aria-expanded='true']::after {
        transform: translateY(-50%) rotate(-180deg);
      }

      &_right {
        &::after {
          content: '\E930'; // ui-icon-arrow-right
        }

        &[aria-expanded='true']::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}
