
@import 'frontend/styles/variables';

.shortcut-bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 202;
  color: $ui-kit-bg-gray-0;
  background-color: rgba($ui-kit-text-secondary, 0.88) !important;

  &__close {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 8px;
  }
}
