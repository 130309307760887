
@import 'frontend/styles/variables';

.base-search {
  display: flex;
  position: relative;

  &_desktop &__button {
    margin-left: 16px;
    flex: 0 0 56px;
  }

  &_desktop .search-site__input-container {
    position: relative;
  }

  .search-site__input-container {
    flex-grow: 1;
  }

  .search-site-dropdown {
    z-index: 2;
    padding: 24px 0;
    position: absolute;
    border-radius: 5px;
    background: $ui-kit-bg-gray-0;
    width: 100%;
    box-sizing: border-box;
    top: 100%;
    font-size: 16px;
    color: $ui-kit-text;
    user-select: none;

    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.07),
      0 1px 18px rgba(0, 0, 0, 0.06),
      0 3px 5px rgba(0, 0, 0, 0.1);

    &__item {
      padding: 0 24px;
    }

    &__preview-text {
      padding: 0 24px;
    }
  }
}

@mixin background-transition-timing {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin reactions($hoverBack, $pressedBack, $fontColor) {
  @include background-transition-timing;

  &:hover {
    background-color: $hoverBack;
    color: $fontColor;
  }

  &:active {
    background-color: $pressedBack;
    color: $fontColor;
  }
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: $ui-kit-bg-gray-60;
  color: $ui-kit-text;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  appearance: none;

  &_red {
    background-color: $ui-kit-secondary;
    color: $ui-kit-bg-gray-0;

    @include reactions(#CE2326, #AB2325, $ui-kit-bg-gray-0);
  }

  &_big {
    height: 56px;
  }

  &_appendable {
    border-radius: 0 4px 4px 0;
  }
}

.b-icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
